<template>
  <generic-section comp-id="blog" height="auto" bg-color="linear-gradient(#FBFCFF, #E8EDFF)">
    <div class="blog-heading-container" id="blog-header-container">
      <h1 class="section-header">{{  $t("BLOG_HEADER") }}</h1>
      <span class="section-text">{{ $t("BLOG_CONTENT") }}</span>
    </div>
    <blog-carousel />
  </generic-section>
</template>

<script>

import GenericSection from "@/components/generic/GenericSection";
import BlogCarousel from "@/components/web/BlogCarousel";
export default {
  name: "Blog",
  components: {
    "generic-section": GenericSection,
    "blog-carousel": BlogCarousel
  }
}
</script>

<style scoped>
.blog-heading-container {
  margin-bottom: 80px;
  opacity: 0;
}
.section-header {
  margin: calc(75px + 4.5vh) 0;
  margin-bottom: 0.7vh;
  font-size: min(50px, 5vh);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e293d;
  will-change: opacity;
}
.section-text {
  margin: 12px 95px 0 104px;
  margin-top: 0.7vh;
  font-size: 2.2vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.8px;
  text-align: center;
  color: #c8c8c8;
  will-change: opacity;
}
  @media screen and (max-width: 992px) {
    .section-header {
      font-size: min(1.7rem, 2.8vh);
      margin: 10px 0;
    }
    .section-text {
      font-size: 0.9rem;
    }
  }
</style>