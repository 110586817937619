<template>
    <div style="width: 100%; position: relative; display: flex; flex-direction: column; align-items: center">
      <div style="max-width: 100%; width: 100%; height: 100%; overflow: hidden; position: relative;" ref="blogRef" />
      <div
          class="blog-mobile-navigation blog-mobile-navigation-back"
          :style="state.currentPage === 0 ? {pointerEvents: 'none', color: 'lightGray'} : {}"
          @click="() => insertNewPage(state.currentPage - 1)">
        <img src="../../assets/arrow-left.png" />
      </div>
      <div
          class="blog-mobile-navigation blog-mobile-navigation-forward"
          :style="state.currentPage === (Math.ceil(blogItems.length / pageSize ) - 1) ? {pointerEvents: 'none', color: 'lightGray'} : {}"
          @click="() => insertNewPage(state.currentPage + 1)">
        <img src="../../assets/arrow-right.png" />
      </div>
      <generic-pagination
          :current-page="state.currentPage"
          :item-size="blogItems.length"
          :page-size="pageSize"
          :on-click="insertNewPage" />
    </div>
</template>

<script>
import { ref, reactive, onMounted, computed, watch, inject, onBeforeUnmount, toRefs } from "vue";
import { useRouter } from "vue-router";
import debounce from "lodash.debounce"

import GenericPagination from "@/components/generic/GenericPagination";
import blogPages from "@/assets/blogAssets/blogPages.js";
import blogHelper from "@/utils/helpers/blogHelper";
import animations from "@/utils/animations";

export default {
  name: "BlogCarousel",
  components: {
    "generic-pagination": GenericPagination
  },
  props: {
    exception: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const { exception } = toRefs(props)
    const blogRef = ref(null);
    const carouselRef = ref(null);
    const pageSize = ref(3);
    const blogItems = computed(() => blogPages.filter(item => exception.value !== item.blogId))
    const state = reactive({
      currentPage: 0
    })

    const global =  inject("global")

    const insertNewPage = debounce(page => {

      const items = blogItems.value.slice(page * pageSize.value, page * pageSize.value + Math.min(pageSize.value, blogItems.value.length - page * pageSize.value))
      const container = blogHelper.createBlogContainer(items, navigateBlog, global.state.isMobile)

      const blogChildren = Array.from(blogRef.value.children)
      const prevChild = blogChildren.slice(-1)[0]

      if(page > state.currentPage) {
        container.style.transform = "translate(100%, -100%)"
        blogRef.value.appendChild(container)
        animations.slideLeftEnter(container)
        animations.slideLeftLeave(prevChild)
        setTimeout(() => {
          blogRef.value.removeChild(prevChild)
          container.style.position = "relative"
        }, 250)

      } else if(page === state.currentPage) {
        container.style.position = "relative"
        blogRef.value.appendChild(container)
        if(blogRef.value.children.length > 1) {
          blogRef.value.removeChild(prevChild)
        }

      } else {
        container.style.transform = "translate(-100%, 0)"
        blogRef.value.appendChild(container)
        animations.slideRightEnter(container)
        animations.slideRightLeave(prevChild)
        setTimeout(() => {
          blogRef.value.removeChild(prevChild)
          container.style.position = "relative"
        }, 250)
      }
      Object.assign(state, {
        currentPage: page
      })

    },150, {leading: true, trailing: false})

    watch(exception, () => {
      setTimeout(() => insertNewPage(0), 150)
    })

    let touchStartX = 0;
    let touchStartY = 0;
    const touchStartFunc = e => {
      touchStartX = e.touches[0].clientX
      touchStartY = e.touches[0].clientY
    }

    const touchEndFunc = e => {
      if(Math.abs(e.changedTouches[0].clientY - touchStartY) < Math.abs(e.changedTouches[0].clientX - touchStartX)) {
        if(e.changedTouches[0].clientX > touchStartX && state.currentPage !== 0) {
          insertNewPage(state.currentPage - 1)
        } else if(e.changedTouches[0].clientX < touchStartX && state.currentPage !== Math.ceil(blogItems.value.length / pageSize.value) - 1 )
         insertNewPage(state.currentPage + 1)
      }
    }
    onMounted(() => {
      insertNewPage(0)
      blogRef.value.addEventListener("touchstart", touchStartFunc)
      blogRef.value.addEventListener("touchend", touchEndFunc)
    })

    onBeforeUnmount(() => {
      const blogChildren = Array.from(blogRef.value.children)
      const prevChild = blogChildren.slice(-1)[0]
      blogRef.value.removeChild(prevChild)
    })


    watch(() => global.state.isMobile, value => {
      if(value) {
        pageSize.value = 1
        setTimeout(() => insertNewPage(state.currentPage), 350)
      }
    })

    const router = useRouter()
    function navigateBlog(blogId) {
      router.push("/blog/" + blogId)
    }

    return {
      blogItems,
      state,
      insertNewPage,
      pageSize,
      carouselRef,
      blogRef
    }
  }
}
</script>

<style scoped>
.blog-carousel {
  display: flex;
  width: 100%;
}
.blog-mobile-navigation {
  position: absolute;
  top: 4.6vw;
  display: flex;
  margin: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.blog-mobile-navigation > img {
  transform: scale(0.5);
}

.blog-mobile-navigation-back {
  left: 0;
  transform: translateX(-100%);
  z-index: 2;
}

.blog-mobile-navigation-forward {
  right: 0;
  transform: translateX(100%);
  z-index: 2;
}

@media screen and (max-width: 992px) {
  .blog-carousel {
    flex-direction: column;
  }
  .blog-mobile-navigation {
    display: flex;

  }
}
</style>