export default {
    createBlogContainer(items, blogNavigation, isMobile) {
        const container = document.createElement("div")
        container.style.width = "100%"
        container.style.overflowX = "hidden"
        container.style.top = "0px"
        container.style.position = "absolute"
        container.style.display = "flex"
        container.style.alignItems = isMobile ? "center" : "flex-start"
        container.style.justifyContent = isMobile ? "center" : "flex-start"
        container.style.flexDirection = isMobile ? "column" : "row"

        items.forEach(item => {
            container.appendChild(this.createBlogCard(item, blogNavigation, isMobile))
        })
        return container
    },
    createBlogCard: (item, blogNavigation, isMobile) => {
        const div = document.createElement("div");
        div.style.display = "inline-flex"
        div.style.flexDirection = "column"
        div.style.boxSizing = "borderBox"
        div.style.cursor = "pointer"
        div.style.minWidth = isMobile ? "80%" : "33.33%"
        div.style.width = isMobile ? "80%" : "33.33%"
        div.style.boxSizing = "border-box"
        div.style.padding = "0 2%"
        div.style.alignItems = "flex-start"
        const imageDiv = document.createElement("div")
        imageDiv.style.width = "100%"
        imageDiv.style.height = "75%"
        imageDiv.style.display = "flex"
        imageDiv.style.justifyContent = "center"
        imageDiv.style.alignItems = "center"
        imageDiv.style.borderRadius = "19px"
        imageDiv.style.width = "100%"
        const img = document.createElement("img")
        img.src = require(`../../assets/blogAssets/blogImages/${item.image}`)
        img.style.maxWidth = "100%"
        img.style.maxHeight = "100%"
        img.style.borderRadius = "10px"
        const heading = document.createElement("h4")
        heading.innerText = item.header
        heading.style.margin = "40px 0 17px 0"
        heading.style.fontSize = "22px"
        heading.style.lineHeight = "30px"
        heading.style.fontWeight = "600"
        heading.style.color = "#2E293C"
        const content = document.createElement("span")
        content.style.textAlign = "start"
        content.style.color = "#9892A3"
        content.style.fontSize= "18px"
        content.style.lineHeight = "150%"
        content.style.display = "-webkit-box"
        content.style.webkitLineClamp = "2"
        content.style.webkitBoxOrient = "vertical"
        content.style.overflow = "hidden"
        content.style.visibility = "visible"
        content.innerText = item.content

        imageDiv.appendChild(img)
        div.appendChild(imageDiv)
        div.appendChild(heading)
        div.appendChild(content)

        div.addEventListener("click", () => blogNavigation(item.blogId))
        return div;
    }
}