<template>
  <div class="pagination-container">
    <div v-for="index in pagesCount"
         v-bind:key="index"
         :class="pageClasses[Math.min(3, Math.abs(currentPage + 1 - index))]"
         class="pagination-page"
         @click="onClick(index - 1)"
    />
  </div>
</template>

<script>
import { computed, watch } from "vue"
export default {
  name: "GenericPagination",
  props: {
    pageSize: {
      type: Number,
      default: 1
    },
    itemSize: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 0
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const pagesCount = computed(() => Math.ceil(props.itemSize * 1.0 / props.pageSize ) )

    const paginateForward = () => {

    }

    const paginateBackward = () => {

    }
    const pageClasses = [
        "pagination-page-active",
        "pagination-page-close",
        "pagination-page-far",
        "hidden"
    ]
    watch(() => props.currentPage, (newValue, oldValue) => {
      if(newValue > oldValue)
        paginateForward()
      else
        paginateBackward()
    })
    return {
      pagesCount,
      pageClasses
    }
  }
}
</script>

<style scoped>
.pagination-container {
  display: flex;
  margin-top: 60px;
}
.pagination-page {
  width: 0;
  height: 8px;
  border-radius: 11.6px;
  background-color: #EEEEEE;
  cursor: pointer;
  margin: 7px;
  transition: all 0.5s;
}
.pagination-page-far {
  width: 11.6px;
}
.hidden {
  opacity: 0;
}
.pagination-page-close {
  width: 16.7px;
}
.pagination-page-active {
  pointer-events: none;
  width: 23.24px;
  background-color: #627DFF;
}
</style>